body {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	background-color: #fff;
	color: #333;
	font-size: 1rem;
	line-height: 22px;
}

h1 {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 2.25rem;
	line-height: 2.5rem;
	font-weight: 500;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 500;
}

h3 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1.75rem;
	line-height: 2rem;
	font-weight: 500;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 500;
}

h5 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 500;
}

h6 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 500;
}

p {
	margin-top: 0px;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

body a {
	color: #2196f3;
	text-decoration: none;
}

ul {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding-left: 40px;
}

ol {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding-left: 40px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

strong {
	font-weight: bold;
}

blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1.25rem;
	border-left: 5px solid #bd071a;
	font-family: Roboto, sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

figure {
	margin-bottom: 20px;
}

figcaption {
	margin-top: 7px;
	color: rgba(51, 51, 51, 0.56);
	font-size: 0.875rem;
	line-height: 20px;
	text-align: center;
}

.button {
	display: inline-block;
	margin-right: 1rem;
	padding: 0.5rem 1.5rem 0.5rem 1rem;
	border: 2px solid transparent;
	border-radius: 0px;
	background-color: #bd071a;
	background-image: url('/20160710165515/assets/images/right-arrow.svg');
	background-position: 95% 50%;
	background-size: 18px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	text-align: center;
	text-decoration: none;
}

.button:hover {
	border-color: #bd071a;
	background-color: transparent;
	background-image: url('/20160710165515/assets/images/right-arrow-red.svg');
	background-position: 95% 50%;
	background-size: 18px;
	background-repeat: no-repeat;
	color: #bd071a;
}

.button.w--current {
	background-color: #2e80b6;
	cursor: pointer;
}

.button.secondary {
	border-color: #bd071a;
	background-color: #fff;
	background-image: url('/20160710165515/assets/images/right-arrow-red.svg');
	background-size: 18px;
	color: #bd071a;
}

.button.secondary:hover {
	border-color: transparent;
	background-color: #bd071a;
	background-image: url('/20160710165515/assets/images/right-arrow.svg');
	background-size: 18px;
	color: #fff;
}

.navigation-link {
	padding: 0.75rem 1.5rem;
	border-bottom: 3px solid transparent;
	font-family: Roboto, sans-serif;
	color: #000;
	font-size: 1rem;
	line-height: 1.25rem;
}

.navigation-link:hover {
	border-bottom-color: #bd071a;
}

.navigation-link.w--current {
	border-bottom-color: #bd071a;
	color: #333;
}

.section-div {
	position: relative;
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.section-div.sponsors {
	position: relative;
	margin-top: -1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-image: -webkit-linear-gradient(180deg, rgba(189, 7, 26, 0.8), #bd071a 50%, rgba(189, 7, 26, 0.8));
	background-image: linear-gradient(270deg, rgba(189, 7, 26, 0.8), #bd071a 50%, rgba(189, 7, 26, 0.8));
}

.section-div.cb-area {
	position: static;
	top: -16px;
	overflow: auto;
	padding: 0rem 0px;
	background-image: url('/20160710165515/assets/images/subtle_dots.png');
}

.section-div.sponsors .cb-text {
	padding-left: 16px;
	padding-right: 16px;
}

.section-div.bottom-cb {
	position: static;
	top: -16px;
	padding: 3rem 0px;
	background-color: #ddd;
}

.section-div.inside {
	padding: 0rem 0px;
	background-color: #ddd;
	background-image: url('/20160710165515/assets/images/p5.png');
	background-size: 8px 8px;
}

.footer {
	position: static;
	top: -16px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #2b2b2e;
}

.utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.container {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 1;
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0rem;
	padding-left: 0rem;
}

.container.cb-container {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.container.header-container {
	z-index: 5;
	height: 9.2rem;
	max-width: 1200px;
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	background-color: transparent;
}

.footer-container {
	left: 0;
	top: 0;
	right: 0;
	z-index: 1;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	display: block;
	padding-right: 1rem;
	padding-left: 1rem;
}

.container.slider {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 0;
}

.container.inside-body {
	padding-right: 1rem;
	padding-bottom: 2rem;
	padding-left: 1rem;
	background-color: #fff;
}

.caption-box-paragraph {
	display: inline-block;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.caption-box-header {
	display: inline-block;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	color: #333;
	font-size: 1.75rem;
	line-height: 2rem;
	font-weight: 500;
}

.list-item {
	padding-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.header-div {
	position: relative;
	z-index: 4;
	border-bottom: 2px solid #bd071a;
}

.logo-home {
	position: relative;
	top: -0.5rem;
	width: 8.75rem;
	height: 9rem;
	margin-bottom: 0.5rem;
	float: left;
	background-image: url('/20160710165515/assets/images/newton-area-chamber-of-commerce-logo-web.svg');
	background-position: 50% 49%;
	background-size: 16rem;
	background-repeat: no-repeat;
}

.logo-home.w--current {
	position: relative;
	top: -0.5rem;
	width: 8.75rem;
	height: 9rem;
	margin-bottom: 0.5rem;
	float: left;
	background-image: url('/20160710165515/assets/images/newton-area-chamber-of-commerce-logo-web.svg');
	background-position: 50% 49%;
	background-size: 16rem;
}

.header-login-button {
	display: inline-block;
	margin-bottom: 5rem;
	padding: 0.5rem 1rem;
	float: right;
	border: 2px solid transparent;
	background-color: #bd071a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1rem;
}

.header-login-button.with-margin {
	margin-right: 12px;
}


.header-login-button:hover {
	border-color: #bd071a;
	background-color: transparent;
	color: #bd071a;
}

.main-navigation {
	position: relative;
	display: inline-block;
	float: right;
	background-color: transparent;
}

.main-navigation-dropdown {
	border-bottom: 3px solid transparent;
}

.main-navigation-dropdown:hover {
	border-bottom-color: #bd071a;
}

.dropdown-toggle {
	position: relative;
	padding: 0.75rem 2.5rem 0.75rem 1.5rem;
	background-color: transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.dropdown-text {
	font-family: Roboto, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.25rem;
}

.dropdown-arrow {
	color: #333;
	cursor: pointer;
}

.search-area {
	display: inline-block;
	margin-bottom: 0px;
	float: right;
}

.nav-menu {
	position: relative;
	display: inline-block;
	float: right;
}

.search-form {
	display: inline-block;
	width: 11rem;
	height: 2rem;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	margin-left: 1.5rem;
}

.text-field {
	display: inline-block;
	width: 9rem;
	height: 2rem;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	font-family: Roboto, sans-serif;
	font-size: 0.75rem;
	line-height: 1rem;
}

.submit-button {
	display: inline-block;
	overflow: visible;
	width: 2rem;
	height: 2rem;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: #bd071a;
	background-image: url('/20160710165515/assets/images/search.svg');
	background-position: 50% 50%;
	background-size: 16px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.submit-button:hover {
	opacity: 0.8;
}

.header-bottom-row {
	position: absolute;
	right: 0.5rem;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 1000px;
	float: right;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.slider-caption-div {
	display: inline-block;
	width: 30rem;
	margin-top: 7rem;
	padding: 1.5rem 2rem;
	float: right;
	background-color: hsla(0, 0%, 100%, 0.7);
}

.sponsors-header {
	display: block;
	margin-right: auto;
	margin-bottom: 0.5rem;
	margin-left: auto;
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 500;
	text-align: center;
}

.sponsors-slider {
	display: block;
	height: 5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.sponsor-image {
	position: static;
	display: inline-block;
	width: 20%;
	height: 5rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.mask {
	display: inline-block;
	width: 80%;
	height: 5rem;
	margin-right: auto;
	margin-left: auto;
}

.sponsors-slide-nav {
	left: 0px;
	right: 0px;
	bottom: -1rem;
	font-size: 0.6875rem;
}

.cb {
	display: inline-block;
	width: 47%;
	height: 10rem;
	background-color: #a8a8a8;
}

.cb.events {
	width: 48%;
	height: auto;
	float: left;
	border: 0px none transparent;
	background-color: transparent;
}

.cb.featured-sponsor {
	width: 48%;
	height: auto;
	padding-bottom: 1rem;
	float: right;
	border: 0px none transparent;
	background-color: transparent;
}

.cb.city {
	position: relative;
	display: inline-block;
	width: 48%;
	height: 20rem;
	padding: 0rem;
	float: left;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-style: none none solid;
	border-width: 0px;
	border-color: transparent transparent #b51747;
	background-color: transparent;
	background-position: 50% 100%;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
}

.cb.city.v2 {
	height: auto;
	background-image: none;
	box-shadow: 2px 2px 8px 0 #a8a8a8;
}

.cb.schools {
	position: relative;
	width: 48%;
	height: 20rem;
	float: right;
	background-color: transparent;
	background-position: 0px 0px;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
}

.cb.schools:hover {
	background-position: 0px 0px;
	background-size: cover;
}

.cb.v2.schools {
	height: auto;
	background-image: none;
	box-shadow: 2px 2px 8px 0 #a8a8a8;
}

.cb-headline-link {
	display: inline-block;
	margin-top: 4.5rem;
	margin-right: auto;
	margin-left: auto;
	padding: 1rem 2.5rem 1rem 2rem;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: rgba(181, 23, 71, 0.9);
	background-image: url('/20160710165515/assets/images/right-arrow.svg');
	background-position: 95% 50%;
	background-size: 26px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
	text-align: center;
	text-decoration: none;
}

.cb-headline-link:hover {
	background-color: #b51747;
	-webkit-transform: translate(10px, 0px);
	-ms-transform: translate(10px, 0px);
	transform: translate(10px, 0px);
}

.cb-headline-link.version-2 {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0.5rem 2rem 0.5rem 1rem;
	float: left;
	background-color: #bd071a;
	background-size: 18px;
	font-size: 1rem;
	line-height: 1.25rem;
}

.cb-paragraph-text {
	display: inline-block;
	margin-bottom: 0.5rem;
	font-family: Roboto, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: left;
}

.cb-paragraph-div {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	padding: 1rem;
	border-top: 0px none transparent;
	background-color: hsla(0, 0%, 100%, 0.7);
}

.cb-paragraph-div.version-2 {
	position: static;
	height: auto;
	background-color: #fff;
}

.cb-static-title {
	margin-bottom: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 5px solid #bd071a;
	background-color: #f1f1f1;
	font-family: Roboto, sans-serif;
	color: #bd071a;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 500;
	text-align: center;
}

.event-listing-div {
	display: inline-block;
	width: 100%;
	height: auto;
	margin-bottom: 7px;
	padding-bottom: 7px;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom: 1px solid #ccc;
}

.event-description {
	position: static;
	bottom: -9px;
	display: inline-block;
	width: 88%;
	margin-top: 7px;
	margin-bottom: 12px;
	margin-left: 67px;
	font-family: Roboto, sans-serif;
	color: #62686e;
	font-size: 0.93rem;
	line-height: 1.25rem;
}

.event-date-text {
	position: static;
	left: 0px;
	top: 0px;
	display: inline-block;
	width: 67px;
	padding-right: 5px;
	font-family: Roboto, sans-serif;
	color: #62686e;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;
}

.featured-sponsor-div {
	margin-top: 2rem;
	padding-right: 4rem;
	padding-left: 4rem;
	text-align: center;
}

.featured-sponsor-logo {
	display: block;
	width: 250px;
	height: 60px;
	margin-right: auto;
	margin-bottom: 1.5rem;
	margin-left: auto;
	background-position: 50% 50%;
	background-size: 290px;
	background-repeat: no-repeat;
}

.featured-sponsor-text {
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: left;
}

.right-arrow-icon {
	margin-right: 0.5rem;
	font-size: 1.375rem;
}

.left-arrow-icon {
	margin-left: 0.5rem;
	font-size: 1.375rem;
}

.footer-contact-div {
	display: inline-block;
	width: 25%;
	margin-right: 1.5rem;
	padding: 1rem 1.5rem;
	background-color: rgba(93, 93, 93, 0.3);
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.25rem;
	float: left;
}

.contact-item-text {
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.8);
	color: #fff;
	line-height: 1.5rem;
}

.contact-item-header {
	margin-bottom: 0.5rem;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-transform: uppercase;
}

.contact-us-button {
	display: block;
	padding: 0.5rem 1.8rem 0.5rem 1rem;
	float: left;
	border: 0px none transparent;
	background-color: #bd071a;
	background-image: url('/20160710165515/assets/images/right-arrow.svg');
	background-position: 95% 50%;
	background-size: 18px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	text-align: center;
}

.contact-us-button:hover {
	border: 0px none transparent;
	background-image: url('/20160710165515/assets/images/right-arrow.svg');
	background-position: 95% 50%;
	background-size: 18px;
	background-repeat: no-repeat;
	-webkit-transform: translate(10px, 0px);
	-ms-transform: translate(10px, 0px);
	transform: translate(10px, 0px);
}

.footer-title {
	margin-bottom: 1rem;
	padding-bottom: 0.75rem;
	border-bottom: 1px solid #bd071a;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 500;
}

.logo-image {
	display: block;
	margin: 1.5rem auto 1rem;
}

.footer-mission-div {
	display: inline-block;
	width: 45%;
	margin-right: 1.5rem;
	padding: 1rem 3rem;
}

.mission-statement-title {
	margin-bottom: 1rem;
	border-bottom: 0px none transparent;
	font-family: Roboto, sans-serif;
	color: #fff;
	font-size: 1.35rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
}

.footer-mission-statement-text {
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	color: #f1f1f1;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
	text-align: center;
}

.footer-mission-statement-text.second-paragraph {
	margin-bottom: 0px;
	font-size: 0.875rem;
	line-height: 1.5rem;
	text-align: center;
}

.footer-links-div {
	position: static;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: inline-block;
	width: 25%;
	padding: 1rem;
	float: right;
}

.connect-with-us {
	display: inline-block;
	margin-right: 1.25rem;
	font-family: Roboto, sans-serif;
	color: #f1f1f1;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 500;
}

.social-link {
	background-image: url('/20160710165515/assets/images/facebook-s-white.svg');
	background-position: 0px 0px;
	background-repeat: no-repeat;
}

.social-link.facebook {
	display: block;
	width: 2rem;
	height: 2rem;
	margin-right: 1rem;
	float: right;
	background-image: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.social-link.facebook:hover {
	opacity: 0.6;
}

.social-link.twitter {
	display: block;
	width: 2rem;
	height: 2rem;
	float: right;
	background-image: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.social-link.twitter:hover {
	opacity: 0.6;
}

.social-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 1.5rem;
	padding-bottom: 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.8);
}

.footer-links {
	display: block;
	margin-bottom: 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #d3d3d3;
	font-size: 1rem;
	line-height: 1.25rem;
	text-align: left;
	text-decoration: underline;
}

.footer-links:hover {
	color: #fff;
}

.grip-logo {
	display: block;
	width: 130px;
	height: 28px;
	margin-right: auto;
	margin-left: auto;
	float: none;
	background-position: 0px 0px;
	background-size: cover;
	background-repeat: no-repeat;
}

.grip-subtext {
	color: #a8a8a8;
	font-size: 0.5rem;
	line-height: 8px;
	text-align: right;
}

.left-sponsor-arrow {
	display: block;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-sponsor-arrow {
	display: block;
	margin-right: 0px;
	margin-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.top-cb-row {
	padding-right: 1rem;
	padding-left: 1rem;
}

.dropdown-list {
	background-color: #b51747;
	min-width: 100%;
}

.dropdown-list.w--open {
	position: absolute;
	left: 0px;
	top: 2.9rem;
	z-index: 2;
	padding: 0.5rem;
	background-color: #bd071a;
}

.dropdown-link {
	border-bottom: 1px solid #fff;
	color: #fff;
}

.dropdown-link:hover {
	background-color: #fff;
	color: #bd071a;
}

.dropdown-link.w--current {
	background-color: #fff;
	color: #bd071a;
	cursor: pointer;
}

.new-hero-slider {
	top: -1rem;
	z-index: 2;
	height: 500px;
}

.slide-one {
	position: relative;
}

.slide-navigation {
	font-size: 0.6875rem;
}

.event-title {
	display: inline-block;
	border-bottom: 1px solid rgba(84, 159, 196, 0.3);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #356aa0;
	font-size: 1rem;
	line-height: 1.5rem;
	text-transform: capitalize;
}

.event-title:hover {
	border-bottom-color: #549fc4;
	color: #b8131b;
}

.copyright {
	margin-bottom: 1.5rem;
	font-family: Roboto, sans-serif;
	color: #d3d3d3;
	font-size: 0.875rem;
	line-height: 1rem;
	text-align: left;
}

.slide-image-wrap {
	height: 550px;
	background-position: 50% 40%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-image-wrap.slide1 {
	position: static;
	height: 500px;
	background-position: 50% 41%;
}

.slide-image-wrap.slide2 {
	background-position: 50% 50%;
}

.signoff {
	position: static;
	top: -16px;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: #222;
	color: #fff;
}

.icon-6 {
	margin-left: 1.5rem;
	font-size: 2rem;
	line-height: 2rem;
}

.icon-7 {
	margin-right: 1.5rem;
	font-size: 2rem;
	line-height: 2rem;
}

.event-listing-top-line {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.city-image {
	display: block;
	width: 100%;
	height: 250px;
	background-position: 50% 83%;
	background-size: cover;
	background-repeat: no-repeat;
}

.schools-image {
	width: 100%;
	height: 250px;
	background-position: 50% 100%;
	background-size: cover;
}

.cb-title-link {
	display: inline-block;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 5px solid #bd071a;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #bd071a;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 500;
}

.cb-title-link:hover {
	background-color: #bd071a;
	color: #fff;
}

.events-view-all-link {
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid rgba(84, 159, 196, 0.3);
	font-family: Roboto, sans-serif;
	color: #356aa0;
	font-size: 1rem;
	line-height: 1.5rem;
}

.events-view-all-link:hover {
	border-bottom-color: #549fc4;
	color: #b8131b;
}

.phone-link {
	display: block;
	margin-bottom: 1rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.8);
	color: #fff;
	line-height: 1.5rem;
}

.border {
	position: relative;
	z-index: 3;
	height: 1rem;
	background-image: url('/20160710165515/assets/images/subtle_dots.png');
	background-position: 0px 0px;
	box-shadow: 0 1px 3px 0 #333;
	opacity: 0.7;
}

.get-adobe-link-block {
	width: 140px;
}

.h1-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #bd071a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #bd071a;
	font-size: 2.25rem;
	line-height: 2.5rem;
	text-decoration: none;
}

.h1-link:hover {
	border-bottom: 2px solid transparent;
	color: #767676;
}

.h2-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid #bd071a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #bd071a;
	font-size: 2rem;
	line-height: 2.25rem;
}

.h2-link:hover {
	border-bottom-color: transparent;
	color: #767676;
}

.h3-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid #bd071a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #bd071a;
	font-size: 1.75rem;
	line-height: 2rem;
}

.h3-link:hover {
	border-bottom-color: transparent;
	color: #767676;
}

.h4-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid #bd071a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #bd071a;
	font-size: 1.5rem;
	line-height: 1.75rem;
}

.h4-link:hover {
	border-bottom-color: transparent;
	color: #767676;
}

.h5-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid #bd071a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #bd071a;
	font-size: 1.25rem;
	line-height: 1.5rem;
}

.h5-link:hover {
	border-bottom-color: transparent;
	color: #767676;
}

.h6-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid #bd071a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #bd071a;
	font-size: 1rem;
	line-height: 1.25rem;
}

.h6-link:hover {
	border-bottom-color: transparent;
	color: #767676;
}

.intro-paragraph {
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.text-link {
	display: inline-block;
	border-bottom: 1px solid #bd071a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #bd071a;
	font-size: 1rem;
	line-height: 1.5rem;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #767676;
}

.text-link.sidebar-link {
	margin-bottom: 1.5rem;
}

.block-quote {
	border-left-color: #bd071a;
	background-color: #e7e7e7;
}

.field-label {
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.form-text-field {
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	background-color: #adebb0;
	margin-bottom: 1rem;
}

.screen-message.error {
	background-color: rgba(189, 7, 26, 0.15);
	margin-bottom: 1rem;
}

.screen-message-text {
	font-family: Roboto, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.left-nav {
	width: 19%;
	height: auto;
	margin-top: 1.5rem;
	margin-right: 1.5rem;
	padding-top: 35px;
	padding-left: 2rem;
	float: left;
	background-color: transparent;
}

.inside-main-content {
	width: 78%;
	padding-top: 1.5rem;
	padding-right: 2rem;
	float: right;
}

.inside-main-content.with-left-right {
	width: 56%;
	padding-right: 0rem;
	float: left;
}

.inside-main-content.with-right {
	padding-right: 0rem;
	padding-left: 2rem;
	float: left;
}

.inside-main-content.full-width {
	display: block;
	width: 95%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0rem;
	float: none;
}

.left-nav-link {
	display: block;
	padding: 0.5rem 0.75rem 0.5rem 0.85rem;
	border-bottom: 1px solid #dbdbdb;
	background-image: url('/20160710165515/assets/images/arrow-medium.svg');
	background-position: -20px 50%;
	background-size: 8px 8px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	color: #333;
	font-size: 0.9rem;
	line-height: 1.25rem;
}

.left-nav-link:hover {
	background-position: 0px 50%;
	-webkit-transform: translate(6px, 0px);
	-ms-transform: translate(6px, 0px);
	transform: translate(6px, 0px);
	color: #bd071a;
}

.left-nav-link.w--current {
	background-position: 0px 50%;
	color: #bd071a;
}

.left-nav-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link.level-2 {
	margin-left: 20px;
	color: #5d5d5d;
	font-size: 0.85rem;
}

.left-nav-link.level-2:hover {
	color: #bd071a;
}

.left-nav-link.level-3 {
	margin-left: 40px;
	color: #5d5d5d;
	font-size: 0.8rem;
}

.left-nav-link.level-3:hover {
	color: #bd071a;
}

.left-nav-link.top-ruler {
	border-top: 1px solid #dbdbdb;
}

.breadcrumbs {
	min-height: 35px;
	margin-bottom: 1.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.5rem;
	border-bottom: 1px solid #dbdbdb;
}

.breadcrumb-link {
	display: inline-block;
	margin-right: 0.7rem;
	padding-right: 0.7rem;
	border-right: 1px solid #dbdbdb;
	font-family: Roboto, sans-serif;
	color: #5d5d5d;
	font-size: 0.875rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.breadcrumb-link:hover {
	color: #bd071a;
}

.breadcrumb-link.w--current {
	border-right-style: none;
	border-right-color: transparent;
	color: #333;
}

.breadcrumb-link.no-right-border {
	border-right-style: none;
	border-right-color: transparent;
}

.right-sidebar {
	width: 20%;
	margin-top: 1.5rem;
	margin-left: 1rem;
	padding-top: 35px;
	padding-right: 2rem;
	float: right;
}

.right-sidebar-title {
	margin-bottom: 0.5rem;
	padding-top: 1rem;
	border-top: 1px solid #dbdbdb;
	font-family: Roboto, sans-serif;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 500;
}

.sidebar-paragraph {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.left-nav-list-item {
	padding-bottom: 0px;
	padding-right: 0.5rem;
}

.breadcrumbs-list {
	margin-bottom: 0rem;
	padding-left: 0px;
}

.breadcrumbs-list-item {
	display: inline-block;
	padding-bottom: 0px;
}

.body {
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

html.w-mod-js *[data-ix="fade-in-bottom-page-loads"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="fade-in-left-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(-50px, 0px);
	-ms-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px);
}

html.w-mod-js *[data-ix="fade-in-right-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(50px, 0px);
	-ms-transform: translate(50px, 0px);
	transform: translate(50px, 0px);
}

html.w-mod-js *[data-ix="fade-in-top-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(0px, -50px);
	-ms-transform: translate(0px, -50px);
	transform: translate(0px, -50px);
}

html.w-mod-js *[data-ix="fade-in-bottom-scroll-in"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="bounce-in-scroll-in"] {
	opacity: 0;
	-webkit-transform: scale(0.6000000000000006, 0.6000000000000006);
	-ms-transform: scale(0.6000000000000006, 0.6000000000000006);
	transform: scale(0.6000000000000006, 0.6000000000000006);
}

html.w-mod-js *[data-ix="scale-on-scroll"] {
	opacity: 0;
	-webkit-transform: scale(0.01, 0.01);
	-ms-transform: scale(0.01, 0.01);
	transform: scale(0.01, 0.01);
}

#authorize-net-form #authorize-net-enter,
#authorize-net-form #authorize-net-submit {
	background-image: none;
}

@media (max-width: 991px) {
	.button {
		position: relative;
	}

	.navigation-link {
		display: block;
		border-bottom: 1px solid #fff;
		color: #fff;
		line-height: 2rem;
	}

	.navigation-link:hover {
		background-color: #fff;
		color: #bd071a;
	}

	.navigation-link.w--current {
		background-color: #000;
		color: #fff;
		cursor: pointer;
	}

	.navigation-link.w--current:hover {
		border-bottom-color: #fff;
	}

	.section-div {
		padding: 35px 10px;
	}

	.section-div.sponsors {
		z-index: 1;
	}

	.section-div.cb-area {
		z-index: 1;
	}

	.footer {
		position: static;
	}

	.container {
		position: relative;
	}

	.container.cb-container {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.container.header-container {
		z-index: 2;
		height: 9.2rem;
	}

	.footer-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container.slider {
		width: 100vw;
		height: 100%;
	}

	.container.inside-body {
		width: 100%;
	}

	.caption-box-paragraph {
		position: relative;
		display: block;
	}

	.caption-box-header {
		position: relative;
	}

	.logo-home.w--current {
		margin-bottom: 0.5rem;
	}

	.main-navigation {
		position: relative;
		display: block;
	}

	.main-navigation-dropdown {
		border-bottom-style: none;
	}

	.dropdown-toggle {
		padding: 0rem;
		border-bottom: 1px solid #fff;
		background-color: transparent;
		font-family: Roboto, sans-serif;
		color: #fff;
		font-size: 1rem;
		line-height: 2rem;
	}

	.dropdown-toggle:hover {
		background-color: #fff;
		color: #bd071a;
	}

	.dropdown-text {
		padding: 0.75rem 2.5rem 0.75rem 1.5rem;
		border-bottom: 0px none transparent;
		color: #fff;
		line-height: 2rem;
	}

	.dropdown-text:hover {
		color: #bd071a;
	}

	.search-area {
		margin-bottom: 0.25rem;
	}

	.nav-menu {
		position: absolute;
		right: 0px;
		z-index: 2;
		display: inline-block;
		width: 75vw;
		margin-top: 56px;
		margin-right: 0px;
		float: none;
		background-color: #bd071a;
	}

	.header-bottom-row {
		position: absolute;
		right: 0rem;
		z-index: 2;
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.slider-caption-div {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 0px;
		width: 100%;
		height: auto;
		margin-right: 3rem;
		background-color: #dbdbdb;
	}

	.sponsors-slider {
		position: relative;
		display: block;
	}

	.mask {
		width: 85%;
	}

	.cb.featured-sponsor {
		height: auto;
		padding-bottom: 1.4rem;
	}

	.cb.city {
		height: 22rem;
	}

	.cb.schools {
		height: 22rem;
		background-position: 0px 0px;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.event-listing-div {
		height: auto;
	}

	.event-description {
		width: auto;
	}

	.event-date-text {
		position: relative;
	}

	.featured-sponsor-div {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.right-arrow-icon {
		margin-right: 0px;
	}

	.left-arrow-icon {
		margin-left: 0px;
	}

	.footer-contact-div {
		width: 28%;
		margin-right: 1rem;
		float: left;
	}

	.footer-mission-div {
		position: static;
		top: 0px;
		right: 0px;
		display: inline-block;
		width: 68%;
		height: auto;
		margin-right: 0rem;
		margin-bottom: 1rem;
		padding: 0.5rem 0rem 0.75rem;
		float: right;
		border-bottom: 1px solid hsla(0, 0%, 66%, 0.8);
	}

	.mission-statement-title {
		margin-bottom: 0.75rem;
		font-size: 1.25rem;
		text-align: left;
	}

	.footer-mission-statement-text {
		margin-bottom: 0.75rem;
		text-align: left;
	}

	.footer-mission-statement-text.second-paragraph {
		font-size: 0.85rem;
		line-height: 1.25rem;
		text-align: left;
	}

	.footer-links-div {
		position: static;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		display: inline-block;
		width: 68%;
		height: auto;
		padding: 0rem;
		float: right;
	}

	.connect-with-us {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.social-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 1rem;
		padding-bottom: 0rem;
		float: none;
		border-bottom: 0px none transparent;
	}

	.footer-links {
		display: inline-block;
		margin-right: 1rem;
		margin-bottom: 0rem;
		float: none;
		text-align: left;
	}

	.left-sponsor-arrow {
		width: 1.5rem;
	}

	.right-sponsor-arrow {
		width: 1.5rem;
	}

	.mobile-menu-button {
		margin-left: 0.5rem;
		padding: 1rem;
		background-color: transparent;
	}

	.mobile-menu-button:hover {
		background-color: transparent;
	}

	.mobile-menu-button:active {
		background-color: transparent;
	}

	.mobile-menu-button.w--open {
		background-color: transparent;
		cursor: pointer;
	}

	.hamburger-icon {
		background-color: transparent;
		color: #333;
		cursor: pointer;
	}

	.top-cb-row {
		position: relative;
		height: auto;
	}

	.new-hero-slider {
		z-index: 1;
		height: 500px;
	}

	.slide-one {
		background-position: 50% 100%;
		background-size: 100vw;
	}

	.slide-navigation {
		position: relative;
		bottom: 40px;
		font-size: 0.875rem;
	}

	.event-title {
		width: auto;
	}

	.copyright {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-bottom: 1rem;
		margin-left: auto;
		float: none;
		font-size: 0.85rem;
		text-align: left;
	}

	.slide-image-wrap.slide1 {
		position: relative;
		height: 300px;
	}

	.slide-image-wrap.slide2 {
		height: 300px;
	}

	.left-arrow {
		position: absolute;
		left: 0px;
		top: -200px;
		height: 300px;
	}

	.right-arrow {
		top: -200px;
		right: 0px;
		height: 300px;
	}

	.icon-6 {
		margin-left: 0.5rem;
		font-size: 1.75rem;
		line-height: 1.75rem;
	}

	.icon-7 {
		margin-right: 0.5rem;
		font-size: 1.75rem;
		line-height: 1.75rem;
	}

	.event-listing-top-line {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.inside-main-content.with-left {
		display: block;
		width: 95%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0rem;
		float: none;
	}

	.inside-main-content.with-left-right {
		display: block;
		width: 95%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.inside-main-content.with-right {
		display: block;
		width: 95%;
		margin-right: auto;
		margin-left: auto;
		padding-left: 0rem;
		float: none;
	}

	.right-sidebar {
		display: block;
		width: 95%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0rem;
		float: none;
	}
}

@media (max-width: 767px) {
	.button {
		font-size: 0.875rem;
		line-height: 1rem;
	}

	.section-div {
		padding-right: 20px;
		padding-left: 20px;
	}

	.section-div.sponsors {
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer {
		height: auto;
		padding-right: 20px;
		padding-left: 20px;
	}

	.container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.container.header-container {
		height: 10.25rem;
		width: 95vw;
	}

	.footer-container {
		position: static;
		display: block;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.caption-box-paragraph {
		line-height: 1.25rem;
	}

	.caption-box-header {
		margin-bottom: 12px;
		font-size: 1.75rem;
		line-height: 2rem;
	}

	.logo-home {
		width: 5.5rem;
		height: 5.5rem;
		background-size: 10rem;
	}

	.logo-home.w--current {
		width: 5.5rem;
		height: 5.5rem;
		background-position: 50% 48%;
		background-size: 10rem;
	}

	.search-area {
		width: 90%;
	}

	.nav-menu {
		margin-right: 0px;
	}

	.search-form {
		width: 19rem;
		margin-left: 0rem;
	}

	.text-field {
		width: 17rem;
	}

	.header-bottom-row {
		left: 2.5%;
		right: 2.5%;
		bottom: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 95%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		border-top: 1px solid #dbdbdb;
	}

	.slider-caption-div {
		width: 100%;
		margin-top: 7rem;
		margin-right: 3rem;
	}

	.sponsor-image {
		width: 33%;
	}

	.mask {
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.cb.events {
		width: 90%;
		height: auto;
		margin-bottom: 1rem;
	}

	.cb.featured-sponsor {
		width: 90%;
		height: auto;
	}

	.cb.city {
		width: 90%;
		margin-bottom: 1rem;
	}

	.cb.city.v2 {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.cb.schools {
		width: 90%;
	}

	.cb.v2.schools {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.event-description {
		width: auto;
		float: right;
	}

	.featured-sponsor-div {
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-contact-div {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-bottom: 1rem;
		margin-left: auto;
		float: none;
	}

	.footer-mission-div {
		position: static;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		padding: 0.75rem 1.5rem 1.25rem;
		float: none;
	}

	.mission-statement-title {
		margin-bottom: 1rem;
		text-align: center;
	}

	.footer-mission-statement-text {
		margin-bottom: 1rem;
		text-align: center;
	}

	.footer-mission-statement-text.second-paragraph {
		text-align: center;
	}

	.footer-links-div {
		position: static;
		top: 0px;
		bottom: 0px;
		display: block;
		width: 90%;
		margin-top: 1.25rem;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		float: none;
	}

	.social-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-links {
		display: block;
		margin-right: 0rem;
		margin-bottom: 1rem;
		text-align: center;
	}

	.grip-logo {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.left-sponsor-arrow {
		width: 1.5rem;
	}

	.top-cb-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.copyright {
		line-height: 1.25rem;
		text-align: center;
	}

	.icon-6 {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

	.icon-7 {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

	.get-adobe-link-block {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}
}

@media (max-width: 479px) {
	.button {
		margin-bottom: 1rem;
	}

	.section-div.sponsors {
		padding-right: 0px;
		padding-left: 0px;
	}

	.section-div.cb-area {
		padding-right: 0px;
		padding-left: 0px;
	}

	.section-div.bottom-cb {
		padding: 2rem 0px;
	}

	.footer {
		position: static;
		padding-right: 0px;
		padding-left: 0px;
	}

	.container.cb-container {
		padding: 2rem 0px;
	}

	.footer-container {
		padding-left: 0px;
	}

	.container.slider {
		width: 100vw;
	}

	.caption-box-paragraph {
		font-size: 0.875rem;
		line-height: 1rem;
	}

	.caption-box-header {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

	.header-div {
		position: relative;
	}

	.logo-home.w--current {
		width: 5.5rem;
		height: 5.5rem;
		margin-right: 1rem;
	}

	.header-login-button {
		padding-right: 0.31rem;
		padding-left: 0.31rem;
		font-size: 0.8rem;
	}

	.search-area {
		display: block;
		width: 90%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.nav-menu {
		margin-right: 0px;
	}

	.search-form {
		width: 75%;
		margin-left: 0rem;
	}

	.text-field {
		width: 75%;
	}

	.header-bottom-row {
		bottom: 0px;
	}

	.slider-caption-div {
		display: inline-block;
		width: 100%;
		height: auto;
		margin-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.sponsors-header {
		font-size: 1.25rem;
		line-height: 1.5rem;
	}

	.sponsor-image {
		width: 50%;
		height: 5rem;
	}

	.mask {
		width: 80%;
	}

	.footer-contact-div {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.footer-mission-div {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-bottom: 1rem;
		margin-left: auto;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.mission-statement-title {
		text-align: center;
	}

	.footer-mission-statement-text {
		text-align: center;
	}

	.footer-links-div {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-links {
		text-align: center;
	}

	.grip-logo {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.left-sponsor-arrow {
		position: absolute;
		left: 0px;
		top: 0px;
		bottom: 0px;
	}

	.right-sponsor-arrow {
		top: 0px;
		right: 0px;
		bottom: 0px;
	}

	.mobile-menu-button {
		margin-left: 0rem;
		padding-right: 5px;
	}

	.hamburger-icon {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

	.new-hero-slider {
		position: relative;
	}

	.slide-one {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.slide-two {
		left: 100vw;
		top: -29.75rem;
		right: 0px;
		display: inline-block;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.signoff {
		position: static;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.icon-6 {
		margin-left: 0.25rem;
	}

	.icon-7 {
		margin-right: 0.25rem;
	}

	.inside-main-content.with-left {
		width: 100%;
	}

	.inside-main-content.with-left-right {
		width: 100%;
	}

	.inside-main-content.with-right {
		width: 100%;
	}

	.inside-main-content.full-width {
		width: 100%;
	}

	.right-sidebar {
		display: block;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.container.header-container {
		width: 94vw;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}


/****************** Webflow Styles *****************/
/****************************************************/
a[href^=tel] {
	color: white;
	line-height: 1.5rem;
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.main-navigation-dropdown:not(.has-dropdown) .dropdown-toggle {
	padding: 0.65rem 1.5rem;
}

.main-navigation-dropdown .dropdown-toggle {
	padding-top: 0.65rem;
	padding-bottom: 0.65rem;
}

.main-navigation-dropdown.active {
	border-bottom-color: #bd071a;
}

input[type="text"].search-text {
	float: left;
	margin-bottom: 5px;
	font-size: 0.75rem;
	line-height: 1rem;
	-webkit-appearance: none;
	border-radius: 10px 0px 0px 10px;
}

.search-submit {
	float: left;
	margin-bottom: 5px;
	font-size: 0px;
}

@media (min-width: 991px) {
	.search-form {
		margin-bottom: 0px;
	}

	.search-submit,
	.search-text {
		margin-bottom: 0px;
		margin-top: 3px;
	}
}

@media (max-width: 991px) {
	.search-form {
		margin-top: 0.6rem;
	}
}

/******************* Mobile Navigation ****************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background: #bd071a;
}

.mobile-navigation-menu li {
	border-bottom: 1px solid #fff;
}

.mm-listview > li:not(.mm-divider)::after {
	border: 0px;
}

.mobile-navigation-menu li a {
	padding: 0.75rem 2.5rem 0.75rem 1.5rem;
	border-bottom: 0px none transparent;
	color: #fff;
	line-height: 2rem;
	font-size: 1rem;
	font-family: Roboto, sans-serif;
}

.mm-listview > li,
.mm-listview > li .mm-next,
.mm-listview > li .mm-next::before,
.mm-listview > li::after {
	border-color: #fff;
}

.mm-listview > li,
.mm-listview > li .mm-next,
.mm-listview > li .mm-next::before,
.mm-listview > li::after {
	border-color: #fff;
}

.mobile-navigation-menu li a:hover {
	color: #bd071a;
	background-color: #fff;
}

.mm-menu .mm-listview > li .mm-next:hover::after {
	border-color: #bd071a;
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span,
.mm-menu .mm-listview > li.active a.link {
	color: #fff;
	background-color: #000000;
}

.mm-menu .mm-listview > li.active {
	border-bottom: 1px solid #bd071a;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	text-align: left;
	color: #fff;
}

.mm-menu .mm-navbar {
	height: 55px;
	border-bottom: 1px solid #fff;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -5px;
}

.mm-navbar .mm-btn:first-child {
	top: 7px;
	left: -5px;
}

.mm-navbar .mm-title {
	padding-top: 17px;
}

.mm-menu .mm-btn::after,
.mm-menu .mm-btn::before {
	border-color: #fff;
}

.mm-menu .mm-listview > li .mm-next::after {
	border-color: #ffffff;
}

/***************** Homepage  **************************/
/******************************************************/
.cb-title-link {
	margin: 0px;
}

.cb-static-title {
	margin-top: 0px;
}

.cb-static-title a,
.cb-title-link a {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	padding-top: 1rem;
	padding-bottom: 1rem;
	display: inline-block;
	width: 100%;
}

.sponsors-header a {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

.cb-static-title.no-padding,
.cb-title-link.no-padding {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.cb-paragraph-text {
	margin-bottom: 0px;
}

.cb-paragraph-text p .featured-sponsor-text p {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	margin-bottom: 0.5rem;
}

.section-div.sponsors {
	color: #fff;
	font-family: inherit;
}

.cb-text .primary,
.cb-text .primary:hover {
	border-color: #bd071a;
	background-color: #bd071a;
	background-image: url('/20160710165515/assets/images/right-arrow.svg');
	color: #fff;
	padding: 0.35rem 2.1rem 0.4rem 0.9rem;
	background-position: 96% 50%;
}

.cb-text .secondary,
.cb-text .secondary:hover {
	border-color: #bd071a;
	background-color: #fff;
	background-image: url('/20160710165515/assets/images/right-arrow-red.svg');
	color: #bd071a;
	padding: 0.35rem 2.1rem 0.4rem 0.9rem;
	background-position: 96% 50%;
}

.cb-text .primary:hover,
.cb-text .secondary:hover {
	-webkit-transform: translate(10px, 0px);
	-ms-transform: translate(10px, 0px);
	transform: translate(10px, 0px);
}

.cb-text .primary a,
.cb-text .secondary a,
.cb-text .primary a:hover,
.cb-text .secondary a:hover {
	color: inherit;
	border-color: transparent;
}

@media (min-width: 767px) {
	.bottom-cb .top-cb-row {
		display: flex;
	}

	.bottom-cb .top-cb-row .cb.city {
		margin-right: 4%;
		background: #ffffff;
	}

	.bottom-cb .top-cb-row .cb.schools {
		background: #ffffff;
	}
}

@media (max-width: 767px) {
	.cb-text .primary,
	.cb-text .primary:hover,
	.cb-text .secondary,
	.cb-text .secondary:hover {
		padding: 0.4rem 2.1rem 0.3rem 0.9rem;
	}
}
/***************** Slideshow  **************************/
/******************************************************/
.caption-box-paragraph {
	margin-bottom: 0px;
}

.caption-box-paragraph p {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

.new-hero-slider .button {
	padding-right: 1.75rem;
}

@media (max-width: 991px) {
	.container.slider {
		position: static;
	}

	.new-hero-slider,
	.new-hero-slider .w-slider-mask {
		height: auto;
	}

	.new-hero-slider .w-slider-mask {
		padding-bottom: 0px;
	}

	.new-hero-slider .slider-caption-div {
		left: 0;
		bottom: 100%;
		width: 100%;
		height: auto;
		top: auto;
		position: static;
		margin: 0px;
		padding-bottom: 40px;
	}

	.new-hero-slider .container.slider {
		height: auto;
		top: auto;
	}

	.new-hero-slider .slide-navigation {
		bottom: 0px;
	}

	.new-hero-slider .slider-caption-div .button {
		margin-bottom: 0rem;
	}
}

@media (max-width: 767px) {
	.new-hero-slider .button {
		font-size: 0.875rem;
		line-height: 1rem;
	}
}

/****************** Inside Page *******************/
/*************************************************/
.inside-body {
	min-height: 450px;
}

.breadcrumb-link.w--current:hover {
	color: #bd071a;
}

.left-nav-link.active {
	background-position: 0px 50%;
	color: #bd071a;
}

.left-nav-list {
	border-top: 1px solid #dbdbdb;
}

.inside-main-content .footer {
	background: none;
	top: auto;
	padding: 0px;
}

img {
	height: auto;
	max-width: 100%;
	display: inline-block;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar .box {
	margin-bottom: 1rem;
	padding-top: 1rem;
	border-top: 1px solid #dbdbdb;
}

.right-sidebar .box * {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.right-sidebar .box h4,
.right-sidebar .box h3 {
	font-family: Roboto, sans-serif;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 500;
	margin-bottom: 0.5rem;
	margin-top: 0px;
}

.right-sidebar .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar.box .contentbox_item_image {
	width: 24px;
}

.right-sidebar .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar .box.tools td:first-child img {
	margin-right: 5px;
}


/*********************** Footer **********************/
/*****************************************************/
.phone-link.desktop {
	display: block;
}

.phone-link.mobile {
	display: none;
}

#GRIPFooterLogo {
	line-height: 11px;
	float: none;
	margin: 0 auto;
	padding-top: 0px !important;
}

.footer-mission-statement-text.second-paragraph p,
.footer-mission-statement-text.second-paragraph a,
.footer-mission-statement-text.second-paragraph li {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
}

.footer-mission-statement-text.second-paragraph p {
	margin-bottom: 0px;
}

.footer-mission-statement-text.second-paragraph h3 {
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	color: #f1f1f1;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 300;
	text-align: center;
}

@media ( max-width: 991px ) {
	.phone-link.desktop {
		display: none;
	}

	.phone-link.mobile {
		display: block;
	}

	.footer-mission-statement-text.second-paragraph h3 {
		margin-bottom: 0.75rem;
		text-align: left;
	}
}

@media ( max-width: 767px ) {
	.footer-mission-statement-text.second-paragraph h3 {
		margin-bottom: 1rem;
		text-align: center;
	}
}

/***************** Inside Page Styles ****************/
/*****************************************************/
/* heading links */
.inside-body h1 a,
.inside-body h2 a,
.inside-body h3 a,
.inside-body h4 a,
.inside-body h5 a,
.inside-body h6 a {
	color: #bd071a;
	border-bottom: 1px solid #bd071a;
	text-decoration: none;
}

.inside-body h1 a:hover,
.inside-body h2 a:hover,
.inside-body h3 a:hover,
.inside-body h4 a:hover,
.inside-body h5 a:hover,
.inside-body h6 a:hover {
	border-bottom-color: transparent;
	color: #767676;
	text-decoration: none;
}

/* links */
.inside-main-content .container a,
.left-sidebar a,
.right-sidebar a,
.cb-text a {
	color: #bd071a;
	border-bottom: 1px solid #bd071a;
	text-decoration: none;
}

.inside-main-content .container a:hover,
.left-sidebar a:hover,
.right-sidebar a:hover,
.cb-text a:hover {
	border-bottom-color: transparent;
	color: #767676;
	text-decoration: none;
}

li {
	padding-bottom: 5px;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

/* buttons */
.button,
.primary,
.secondary,
.tertiary,
.inside-body button[type="submit"],
.cms_form_button.primary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button,
a.primary,
a.secondary,
a.tertiary,
a.button,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
.inside-main-content .container a.primary,
.inside-main-content .container a.secondary,
.inside-main-content .container a.tertiary {
	padding: 0.5rem 1.5rem 0.5rem 1rem;
	border: 2px solid transparent;
	border-radius: 0px;
	background-position: 95% 50%;
	background-size: 18px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.25rem;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	display: inline-block;
	-webkit-appearance: none;
}

.button,
.primary,
.inside-body button[type="submit"],
.cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
a.primary,
a.button,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
.inside-main-content .container a.primary {
	background-color: #bd071a;
	color: #fff;
	background-image: url('/20160710165515/assets/images/right-arrow.svg');
}

.button:hover,
.primary:hover,
.inside-body button[type="submit"]:hover,
.cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
a.primary:hover,
a.button:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
.inside-main-content .container a.primary:hover {
	border-color: #bd071a;
	background-color: transparent;
	background-image: url('/20160710165515/assets/images/right-arrow-red.svg');
	background-position: 95% 50%;
	background-size: 18px;
	background-repeat: no-repeat;
	color: #bd071a;
}

.secondary,
.tertiary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .secondary,
a.secondary,
.inside-main-content .container a.secondary {
	border-color: #bd071a;
	background-color: #fff;
	background-image: url('/20160710165515/assets/images/right-arrow-red.svg');
	background-size: 18px;
	color: #bd071a;
}

.secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .secondary:hover,
a.secondary:hover,
.inside-main-content .container a.secondary:hover {
	border-color: transparent;
	background-color: #bd071a;
	background-image: url('/20160710165515/assets/images/right-arrow.svg');
	background-size: 18px;
	color: #fff;
}

#submissionforms_module.cms_form .cms_buttons .tertiary,
a.tertiary,
.inside-main-content .container a.tertiary {
	background-image: none;
	padding-right: 1rem;
	border: 1px solid #bd071a;
	padding-top: 0.56rem;
	padding-bottom: 0.56rem;
}

a.tertiary:hover,
.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .tertiary:hover,
.inside-main-content .container a.tertiary:hover {
	background-image: none;
	background-color: #bd071a;
	color: #fff;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #adebb0;
	font-family: Roboto, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: rgba(189, 7, 26, 0.15);
	font-family: Roboto, sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}


/*tables*/
table.styled {
	background: white;
	margin-bottom: 20px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
	border-bottom: 1px solid white;
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #bd071a;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #444;
	color: white;
}

table.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #dbdbdb;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

table.styled tr:last-child td {
	border-bottom: 1px solid #bd071a;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea {
	max-width: 100%;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	padding: 5px 12px;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	box-shadow: none;
	-webkit-appearance: none;
}

select {
	max-width: 100%;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	padding: 9px 12px;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	box-shadow: none;
}

textarea {
	resize: vertical;
}

label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-body input[type="text"],
	.inside-body input[type="tel"],
	.inside-body input[type="password"],
	.inside-body input[type="email"],
	input[type="search"],
	.inside-body select,
	.inside-body textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left-color: #bd071a;
	background-color: #e7e7e7;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}


/******************* Content Modules ******************/
/*****************************************************/
/* rss feeds */
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

#feeds_module.cms_list .cms_item_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

/* news */
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-bottom: 0.9rem;
	margin-top: 1.5rem;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
	}
}

/* partners */
#partners_module.cms_list .cms_item_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td:not(.image) {
	padding-left: 15px;
}

#partners_module.cms_list .cms_list_item table td.image {
	background-color: #bd071a;
	text-align: center;
	vertical-align: middle;
	padding-left: 15px;
	padding-right: 15px;
	width: 180px;
}

#partners_module.cms_list .cms_list_item table td.image a {
	border-bottom: 0px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
	background-color: #bd071a;
	display: inline-block;
	padding: 15px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
	}

	#partners_module.cms_list .cms_list_item table td:not(.image) {
		padding-left: 0px;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		margin-bottom: 10px;
		padding: 15px;
	}
}

/* photo llbums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 150px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#staffdirectory_module.cms_list .cms_list_item .cms_title {
	font-weight: 500;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.9rem;
	color: inherit;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 37%;
}

#submissionforms_module.cms_form #form-payment-info #payment-info .ui-form-panel {
	padding-top: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-fieldset:not(#payment-info) .ui-form-panel {
	padding-bottom: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .s-payment-method-label {
	display: inline;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
}


#submissionforms_module.cms_form #form-payment-info .ui-form-field.required .ui-form-label em {
	color: #ee0000;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-expiration-date-fields > div:first-of-type {
	margin-bottom: 10px;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

.module-forms.confirmation .step-title {
	font-family: Roboto, sans-serif;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 500;
	margin-top: 1rem;
	margin-bottom: 1rem;
	color: #333;
}

.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

.ui-form-text-phone-extension {
	margin-left: 0px;
	display: inline-block;
	width: 100%;
	margin-top: 10px;
}

.ui-form-text-phone-extension .ui-form-text-phone-extension-field {
	max-width: 100%;
	width: 120px;
}

#submissionforms_module.cms_form .required_information em,
#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 300px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: Roboto, sans-serif;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 500;
	border-bottom: 0px;
}

#account_module.form .form_item .label label {
	display: inline;
}

@media (max-width: 991px) {

	#account_module.form .form_container .login_field {
		max-width: 100%;
	}

	#account_module.form .form_item.required .field {
		background: none;
	}

	#account_module.form .form_item .field {
		padding-left: 0px;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		width: auto;
		text-align: left;
		display: inline-block;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}
}


/* search */
.search-collection > .search-node {
	border-top: #cccccc solid 1px;
	margin: 1rem 0 0 0;
	padding-top: 1rem;
}

/******************* Content Boxes *******************/
/*****************************************************/
/* news */
#news_module.homepage_contentbox .cms_title h3 {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

#news_module.homepage_contentbox .cms_date h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	font-size: 0.9rem;
	line-height: 1.1rem;
	font-weight: 400;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 20px;
}

.new-hero-slider #news_module.homepage_contentbox {
	text-align: left;
	padding: 20px;
}

.section-div.sponsors #news_module.homepage_contentbox a,
.section-div.sponsors #news_module.homepage_contentbox a:hover {
	color: #fff;
	border-bottom: 0px;
}

/* rss */
#feeds_module.homepage_contentbox .event-title,
#feeds_module.homepage_contentbox .events-view-all-link {
	border-bottom: 1px solid rgba(84, 159, 196, 0.3);
	color: #356aa0;
}

#feeds_module.homepage_contentbox .event-title:hover,
#feeds_module.homepage_contentbox .events-view-all-link:hover {
	border-bottom-color: #549fc4;
	color: #b8131b;
}

.section-div.sponsors #feeds_module.homepage_contentbox a,
.section-div.sponsors #feeds_module.homepage_contentbox a:hover,
.section-div.sponsors #feeds_module.homepage_contentbox .event-description,
.section-div.sponsors #feeds_module.homepage_contentbox .event-date-text {
	color: #fff;
	border-bottom: 0px;
}

.new-hero-slider #feeds_module.homepage_contentbox {
	margin: 20px;
}

/* partners */
#owl-carousel-partners.owl-carousel {
	height: 5rem;
	width: 80%;
	margin-right: auto;
	margin-left: auto;
}

#owl-carousel-partners.owl-carousel .item img {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
}

#owl-carousel-partners.owl-carousel .owl-dots {
	display: none;
}

#owl-carousel-partners.owl-carousel .owl-prev {
	font-size: 0rem;
	position: absolute;
	bottom: 23%;
	left: -11.5%;
}

#owl-carousel-partners.owl-carousel .owl-next {
	font-size: 0px;
	position: absolute;
	bottom: 23%;
	right: -11.5%;
}

#owl-carousel-partners.owl-carousel .owl-prev:before {
	content: "\e601";
	font-family: 'webflow-icons';
	font-size: 1.375rem;
	cursor: pointer;
}

#owl-carousel-partners.owl-carousel .owl-next:before {
	content: "\e600";
	font-family: 'webflow-icons';
	font-size: 1.375rem;
	cursor: pointer;
}

#owl-carousel-partners.owl-carousel .owl-stage,
#owl-carousel-partners.owl-carousel .owl-stage-outer,
#owl-carousel-partners.owl-carousel .owl-item,
#owl-carousel-partners.owl-carousel .owl-item .item {
	height: 100%;
}

#owl-carousel-partners.owl-carousel .owl-item .item {
	display: flex;
	align-items: center;
	justify-content: center;
}

#owl-carousel-partners.owl-carousel .owl-item .item a {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	border-bottom: 0px;
}

@media (max-width: 991px) {
	#owl-carousel-partners.owl-carousel {
		width: 85%;
	}

	#owl-carousel-partners.owl-carousel .owl-prev {
		left: -8%;
	}

	#owl-carousel-partners.owl-carousel .owl-next {
		right: -8%;
	}
}

/* form */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	padding: 10px;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="search"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	font-family: Roboto, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	padding: 5px 12px;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	color: inherit;
	font-family: inherit;
}


/******************* UI Forms *******************/
/*****************************************************/
@media (max-width: 991px) {
	.ui-form-select > select,
	.ui-form-textarea > textarea,
	.ui-form-text > input[type="password"],
	.ui-form-text > input[type="text"] {
		width: 100%;
		max-width: 100%;
	}

	.ui-form-label {
		height: auto;
		left: 0;
		line-height: 18px;
		padding: 6px 6px 6px 0;
		position: static;
		text-align: left;
		top: auto;
		width: 100%;
	}

	.ui-form-fields {
		padding: 0;
	}
}
