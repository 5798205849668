/*********** Color Contrast Issues ******************/
/***************************************************/
/* /core/libraries/jquery.mmenu/assets/6.1.3/jquery.mmenu.all.min.css */
.mm-menu.mm-theme-dark .mm-search input {
	background: #757575;
	color: #ffffff;
}

/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
p.error {
	background-color: #e00000;
	color: #fff;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #1f78d1 !important;
	color: #ffffff !important;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #6e6e6e;
	color: #ffffff;
}



/*********** Relative Unit Values ******************/
/***************************************************/
/* /core/libraries/jquery.mmenu/assets/6.1.3/jquery.mmenu.all.min.css */
.mm-listview {
	font-size: 0.875rem;
}

.mm-listview .mm-divider {
	font-size: 0.625rem;
}

/* core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
.typed {
	font: normal 1.875em/3.125rem "Journal", Georgia, Times, serif;
}

/* core/libraries/magnificPopup/assets/1.0.0/magnific-popup.css */
.mfp-counter {
	font-size: 0.75rem;
}

.mfp-figure small {
	font-size: 0.75rem;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	font-size: 1.25rem;
}

/* /core/website/modules/feeds/css/screen.css.cfm */
#feeds_module.cms_list .cms_list_item .cms_date {
	font-size: 0.75rem;
}

#feeds_module.cms_list .cms_list_item .cms_metadata2.cms_date {
	font-size: 0.6875rem;
}

#feeds_module.homepage_contentbox .item .date-published {
	font-size: 0.6875rem;
}

/* /core/website/modules/photoalbums/css/screen.css */
#photoalbums_module.cms_entity .slideshow .post {
	font-size: 0.625rem;
}

#photoalbums_module.cms_entity .slideshow-info {
	font-size: 0.625rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	font-size: 0.6875rem;
}

/* /core/website/modules/submissionforms/css/print.css */
.module-forms.confirmation .step-title {
	font-size: 0.875rem;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .agreement_hint {
	font-size: 6875rem;
}

.module-forms.confirmation .step-title {
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 0.875rem;
}



/********* Ensure that all information conveyed with color is available without color *****/
/******************************************************************************************/
/* /core/public/shared/assets/css/responsive.css */
.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #999999;
	font-style: italic;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_steps_wrapper .active,
#submissionforms_module.cms_form .cms_steps_wrapper .last_active {
	background-color: #d1d0ce;
	font-style: italic;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.container-button:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.container-button:hover {
	background-color: hsla(90, 20%, 65%, 0.15);
	box-shadow: 0px 1px 4px #9ba694;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.add:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.add:hover {
	color: #348300;
	box-shadow: 0px 1px 4px #9ba694;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.remove:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.remove:hover {
	color: #d3121a;
	box-shadow: 0px 1px 4px #9ba694;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	background-color: #dddddd;
	cursor: pointer;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover {
	background-color: #dddddd;
	cursor: pointer;
}
